<template>
     <CForm ref="form" class="needs-validation">
        <CRow>
            <CCol  tag="label" sm="3" class="col-form-label">
                Slider hangi sırada olsun
            </CCol>
            <CCol>
                <CInput
                    horizontal
                    v-model="data.mainPagePriority"
                    type="number"
                    required
                />    
            </CCol>
            
        </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "KLAddPhoto",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            validated: Boolean,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },

        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>